import React, { useRef, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/mentorBio"
import LiveChat from "../components/sales-pages/liveChat"
import { useStaticQuery, graphql } from "gatsby"
import { Form, FormState } from "gatsby-theme-firebase"
import Image from "gatsby-image"
import { useAuth } from "gatsby-theme-firebase"

import "../styles/education.scss"
import "../styles/content.scss"

export default ({ pageContext: { course } }) => {
  const { title, subtitle } = course
  const { isLoggedIn, isLoading } = useAuth()
  const [hasPurchased, setIfPurchased] = useState(false)
  const myRef = useRef(null)
  const images = useStaticQuery(graphql`
    query consultingQuery {
      jordan: file(
        absolutePath: { regex: "/education/consulting/jordan.jpg/" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/education/consulting.jpg/" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)

  const ctaClick = ref => {
    if (typeof window !== "undefined")
      window.scrollTo(0, myRef.current.offsetTop)
  }

  return (
    <Layout>
      <SEO
        title={`${title}: ${subtitle}`}
        description={subtitle}
        image={images.logo.childImageSharp.fixed.src}
      />{" "}
      <div className="hero">
        <div className="headline">
          <h1> {title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
          <div style={{ maxWidth: "400px", margin: "1rem auto" }}>
            <button className="cta-button" onClick={ctaClick}>
              Schedule a Call!
            </button>
          </div>
        </div>
      </div>
      <div className="course-sales-content">
        <div className="row">
          <div className="column50">
            <Image
              fluid={images.jordan.childImageSharp.fluid}
              style={{ overflow: "hidden" }}
              alt={"Jordan"}
            />
          </div>
          <div className="column50">
            <p className="video-explainer">
              Over the years, Jordan has proven time and time again he knows how
              to build and grow businesses. From starting a simple tutoring
              company in college, to building a 6-figure Amazon FBA business,
              to&nbsp;exiting&nbsp;a SaaS for&nbsp;
              <strong>multi-6-figures</strong>,&nbsp;he's done just about
              everything.
              <button className="cta-button" onClick={ctaClick}>
                Schedule a Call!
              </button>
            </p>
          </div>
        </div>
        <div id="content">
          <p>
            If you value your time (
            <em>and if you're reading this page, you do</em>), then scheduling a
            call right now is a simple no-brainer. During the call we can
            discuss anything. Starting out?&nbsp;<strong>Let's go</strong>.
            Stuck on trying to build something?&nbsp;
            <strong>No worries.</strong> Trouble growing?&nbsp;
            <strong>
              <u>Not a problem.</u>
            </strong>
          </p>
          <p>
            Below are some of the experiences and accomplishes that Jordan has
            had so you know your time and money is well spent on a call with
            him:
          </p>
          <ul className="fancy-checks">
            <li>
              Built a bootstrapped <strong>Amazon FBA business</strong> with
              only a few hundred dollars.
              <ul className="fancy-checks">
                <li>
                  Became an expert in all 4 methods (
                  <em>RA, OA, PL, &amp; Wholesale</em>) through{" "}
                  <strong>rigorous practice</strong>.
                </li>
                <li>
                  Hired employees locally and abroad to help run the business.
                </li>
                <li>
                  Scaled well into the <strong>6 figures</strong> by bringing on
                  more SKUs and marketing.
                </li>
              </ul>
            </li>
            <li>
              Grew a massive <strong>following on</strong>{" "}
              <strong>YouTube</strong> in just a few months.
              <ul className="fancy-checks">
                <li>
                  Gained <em>50k+ subscribers</em> in a mere <em>4 months</em>{" "}
                  utilizing trend and viral research.
                </li>
                <li>
                  Capitalized using funnels and products that&nbsp;correlated
                  with the channel.
                </li>
                <li>
                  Scaled mentoring and consulting to{" "}
                  <strong>$1MM in profit&nbsp;</strong>in a single fiscal year.
                </li>
              </ul>
            </li>
            <li>
              <strong>Grew a SaaS</strong> in the eCom market.&nbsp;
              <ul className="fancy-checks">
                <li>
                  Learned to code and built an entire platform{" "}
                  <span style={{ textDecoration: "underline" }}>
                    single-handedly
                  </span>
                  .&nbsp;
                </li>
                <li>
                  Immediately grew the SaaS to <strong>20k M.R.R.</strong> (
                  <em>monthly recurring revenue</em>).
                </li>
                <li>
                  Continued to serve customers and add new products to increase
                  value.
                </li>
                <li>
                  <em>Exited</em> the SaaS just 18 months after launching for{" "}
                  <strong>over $350,000</strong>.
                </li>
              </ul>
            </li>
          </ul>
        </div>{" "}
        <br />
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <button className="cta-button" onClick={ctaClick}>
            Schedule a Call!
          </button>
        </div>
        <br />
        <br />
        <hr />
        <div className="row">
          <Bio />
        </div>
        <br />
        <hr />
        <br />
        <div ref={myRef}>
          {!hasPurchased ? (
            !isLoading && (
              <FormState.Provider>
                <Form
                  isLoggedIn={isLoggedIn}
                  course={course}
                  onSignUpSuccess={user => {
                    setIfPurchased(true)
                  }}
                  onLoginSuccess={user => {
                    // if (typeof window !== "undefined")
                    //   window.location.reload(false)
                  }}
                />
              </FormState.Provider>
            )
          ) : (
            <div>
              <h2>Payment Complete!</h2>
              <div>
                Schedule a call below:
                <br />
                If you have any issues, schedule with me directly:{" "}
                <a href="mailto:jordan@millionairemillennial.me">jordan@millionairemillennial.me</a>
              </div>
              <br />
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/jordankilburn/30m-consulting-call"
                style={{
                  margin: "auto",
                  maxWidth: "620px",
                  minWidth: "320px",
                  height: "900px",
                }}
              />
            </div>
          )}
        </div>
      </div>
      <LiveChat />
    </Layout>
  )
}
